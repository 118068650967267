import React from "react";
import { css } from "twin.macro";
import { Global } from "@emotion/react";
import PropTypes from "prop-types";
import DiamondPrefetch from "~components/DiamondPrefetch.jsx";

const Layout = ({ children, styles }) => (
  <>
    <Global
      styles={[
        css`
          a:focus,
          button:focus,
          input:focus,
          textarea:focus {
            outline: none;
          }
        `
      ]}
    />

    <DiamondPrefetch />

    <main id="layout" css={[...styles]}>
      {children}
    </main>
  </>
);

Layout.defaultProps = {
  styles: []
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  styles: PropTypes.arrayOf(PropTypes.shape({}))
};

export default Layout;
