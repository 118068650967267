import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

// disabled on client request
const SEO = ({
  customDescription,
  customKeywords,
  customTitle,
  noIndex,
  path
}) => (
  <Helmet title="Diamond Selector" titleTemplate="Frank Darling | %s">
    <meta name="robots" content="noindex" />
  </Helmet>
);

//

// const { sanitySeo } = useStaticQuery(graphql`
//   query SEO {
//     sanitySeo {
//       url
//       title
//       titleTemplate
//       description
//       keywords
//       fbAppId
//       twitterUsername

//       shareImage {
//         asset {
//           fluid {
//             src
//           }
//         }
//       }
//     }
//   }
// `);

// const {
//   url,
//   title,
//   titleTemplate,
//   description,
//   keywords,
//   shareImage,
//   fbAppId,
//   twitterUsername
// } = sanitySeo;

// let parsedKeywords = ``;

// if (Array.isArray(customKeywords) && customKeywords?.[0]) {
//   parsedKeywords = customKeywords.join(`,`);
// } else if (Array.isArray(keywords) && keywords?.[0]) {
//   parsedKeywords = keywords.join(`,`);
// }

// const seo = {
//   url: `${url}${path ? `/${path}` : ``}`,
//   title: customTitle || title,
//   description: customDescription || description,
//   keywords: parsedKeywords,
//   //
//   image: shareImage?.asset?.fluid?.src || null,
//   fbAppId,
//   twitterUsername
// };

// if (noIndex) {
//   return (
//     <Helmet title={seo.title} titleTemplate={titleTemplate}>
//       <meta name="robots" content="noindex" />
//     </Helmet>
//   );
// }

// return (
//   <Helmet title={seo.title} titleTemplate={titleTemplate}>
//     <html lang="en" />
//     <meta property="og:type" content="website" />

//     {url && <meta property="og:url" content={url} />}

//     {seo.title && <meta property="og:title" content={seo.title} />}
//     {seo.title && <meta name="twitter:title" content={seo.title} />}

//     {seo.description && <meta name="description" content={seo.description} />}
//     {seo.description && (
//       <meta property="og:description" content={seo.description} />
//     )}
//     {seo.description && (
//       <meta name="twitter:description" content={seo.description} />
//     )}

//     {seo.keywords && <meta name="keywords" content={seo.keywords} />}

//     {seo.image && <meta name="image" content={seo.image} />}
//     {seo.image && <meta property="og:image" content={seo.image} />}
//     {seo.image && <meta name="twitter:card" content="summary_large_image" />}
//     {seo.image && <meta name="twitter:image" content={seo.image} />}

//     {fbAppId && <meta property="fb:app_id" content={fbAppId} />}

//     {twitterUsername && (
//       <meta name="twitter:creator" content={twitterUsername} />
//     )}
//   </Helmet>
// );
SEO.defaultProps = {
  customDescription: null,
  customKeywords: null,
  customTitle: null,
  noIndex: false,
  path: null
};

SEO.propTypes = {
  customDescription: PropTypes.string,
  customKeywords: PropTypes.arrayOf(PropTypes.string),
  customTitle: PropTypes.string,
  noIndex: PropTypes.bool,
  path: PropTypes.string
};

export default SEO;
