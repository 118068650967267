/* eslint-disable react/prop-types */

import React, { useState } from "react";
import { PropTypes } from "prop-types";
import tw, { css, theme } from "twin.macro";
import * as T from "~components/styles/Typography.jsx";

const Button = ({ color, disabled, fontSize, onClick, styles, text }) => {
  const [hovered, setHovered] = useState(false);

  let defaultBackground = `transparent`;
  let hoverBackground = theme`colors.koamaru`;
  let defaultColor = theme`colors.koamaru`;
  let hoverColor = theme`colors.white`;

  switch (color) {
    case `white`: {
      defaultBackground = `transparent`;
      hoverBackground = theme`colors.white`;
      defaultColor = theme`colors.white`;
      hoverColor = theme`colors.black`;

      break;
    }

    default:
      break;
  }

  //

  return (
    <button
      type="button"
      css={[
        ...styles,
        css`
          height: 3rem;
          background-color: ${defaultBackground};
          color: ${defaultColor};
          border: 1px solid ${defaultColor};
          pointer-events: ${disabled ? `none` : `auto`};

          &:hover {
            background-color: ${hoverBackground};
            color: ${hoverColor};
          }
        `,
        tw`relative block`
      ]}
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div tw="w-full h-full relative flex items-center justify-center">
        {(fontSize?.toLowerCase() === `l` && (
          <T.Body font="1">{text}</T.Body>
        )) || <T.Button>{text}</T.Button>}
      </div>
    </button>
  );
};

Button.defaultProps = {
  color: `koamaru`,
  disabled: false,
  onClick: () => {},
  styles: [],
  text: `Submit`
};

Button.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  styles: PropTypes.arrayOf(PropTypes.shape({})),
  text: PropTypes.string
};

export default Button;
